import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import axios from "axios";

import HeaderMDN from '../Header/HeaderMDN';
import FooterMDN from '../Header/FooterMDN';


import "../Blog/blog.css";


const about_article_url_en = "from-fighting-global-warming-to-making-a-social-media-scheduler";
const about_article_url_fr = "du-rechauffement-climatique-aux-reseaux-sociaux";


const About = () => {
    const articleContentOutputRef = React.createRef();
    const { t, i18n } = useTranslation(['about', 'seo']);

    const initArticleData = {
        title: "",
        subtitle: "",
        main_img_url: "",
        content: ""
    }

    const [articleData, setArticleData] = useState(initArticleData);

    const fetchAboutData = async () => {

        let post_url = about_article_url_en;
        if (i18n.language === "fr") {
            post_url = about_article_url_fr;
        }

        console.log(post_url);

        await axios
            .get(process.env.REACT_APP_PIKA_API_URI + "/blog/get_article_by_url/" + post_url)
            .then((response) => {
                if (response.data[0]) {
                    setArticleData(response.data[0]);
                }
            })
            .catch(() => {
            });

    }

    useEffect(() => {
        fetchAboutData();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        const formatted_input = articleData.content;
        articleContentOutputRef.current.innerHTML = formatted_input;
    }, [articleData]);


    return (
        <div>
            <Helmet title={t('seo_about_title', { ns: 'seo' })}
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        property: "twitter:card",
                        content: "summary_large_image"
                    },
                    {
                        property: "twitter:site",
                        content: "@social_pika"
                    },
                    {
                        property: "twitter:title",
                        content: t('seo_about_title', { ns: 'seo' })
                    },
                    {
                        property: "twitter:description",
                        content: t('seo_landing_description', { ns: 'seo' })
                    },
                    {
                        property: "twitter:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "og:url",
                        content: "https://www.socialpika.com/about"
                    },
                    {
                        property: "og:type",
                        content: "website"
                    },
                    {
                        property: "og:title",
                        content: t('seo_about_title', { ns: 'seo' })
                    },
                    {
                        property: "og:description",
                        content: t('seo_landing_description', { ns: 'seo' })
                    },
                    {
                        property: "og:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "fb:app_id",
                        content: process.env.REACT_APP_FB_APP_ID
                    }
                ]} >
                <link rel="canonical" href="https://www.socialpika.com/about" />
            </Helmet>
            <HeaderMDN />
            <div className="blog-container">
                <div className='margin-t8'>
                    <h1>{articleData.title}</h1>
                    <h4>{articleData.subtitle}</h4>
                    <img className='blog-img' src={articleData.main_img_url} alt={articleData.subtitle} />
                </div>
                <div className='blog-section-content margin-t8'>
                    <div className='blog-content-block' ref={articleContentOutputRef}>
                        {articleData.content}
                    </div>
                </div>
            </div>
            <FooterMDN />
        </div>
    )
}

export default About


/*

<h3 className='section-heading margin'>Hello I'm P.Y</h3>
                    <h4>I'm the founder, designer, engineer and support of SocialPika</h4>
                    <p>I was born and raised in France, lived in Asia for 10 years before moving back to Europe in Switzerland in 2015. I previously worked in different industrial sectors (energy, connectors, watch making) before embarking in a startup project in 2019.</p>
                    <p>The story starts back in 2019 when my friend F.X and I embarked on a Startup Project to help reducing the carbon emissions of oil boilers still massively used in Europe to heat up homes.</p>


                    <div className="testimonial">
                        <img className="img-fluid" src={PYProfile} alt={t('why_I_launched_title')} />
                        <div className="text-wrapper margin-s2">
                            <div className="testimonial-text">{t('why_I_launched_1')}<a className="underlined_link" href="https://www.enaliz.com" target="_blank" rel="noreferrer DOFOLLOW">Enaliz</a><sup>*</sup>{t('why_I_launched_2')}</div>
                            <div className="testimonial-text">{t('why_I_launched_3')}</div>
                            <div className="testimonial-author">P.Y (<svg viewBox="0 0 24 24">
                                <path fill="current" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                            </svg>
                                <a href="https://twitter.com/py_saas" target="_blank" rel="noreferrer DOFOLLOW">@py_saas</a>)</div>
                            <div className="testimonial-ps"><sup>*</sup>{t('why_I_launched_sub')}<a href="https://www.enaliz.com" target="_blank" rel="noreferrer DOFOLLOW">{t('check_it_out')}</a></div>
                        </div>
                    </div>



                    */