import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import ReactGA from 'react-ga';


import App from './App';
import Loader from './components/Loader';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize(process.env.REACT_APP_GG_ANALYTICS); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);



i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'fr'],
    fallbackLng: "en",
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
    // backend: {
    //   loadPath: '/assets/locales/{{lng}}/translation.json',
    // },

  });

ReactDOM.render(
  <Suspense fallback={Loader}>
    <BrowserRouter>
      <Route path="/" component={App} />
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
