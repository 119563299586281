import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Aos from "aos";
import "aos/dist/aos.css";
import { isMobile } from "react-device-detect";

import "../styles/mdn_testimonials.css";
import "../styles/mdn_kanban.css";

import HeaderMDN from "../Header/HeaderMDN";
import FooterMDN from "../Header/FooterMDN";
import FaqMDN from "./FaqMDN";

import SPKHero from "../../images/cesam/socialpika-intro.png";
import SPKHeroArrow from "../../images/cesam/arrow.png";

import PartnerAvis from "../../images/partners/Partner-Avis-w.png";
import PartnerPDS from "../../images/partners/Partner-PDS-w.png";
import PartnerLB from "../../images/partners/Partner-LB-w.png";
import PartnerPS from "../../images/partners/Partner-PS-w.png";

import PostCreation from "../../images/description/post_creation.png";
import ContentAI from "../../images/description/content-ai-ikea.png";
import ScheduleImg from "../../images/description/schedule.png";
import Analytics from "../../images/description/analytics.png";
import Engage from "../../images/description/engage.png";

// import Partner7 from "../../images/partners/partner7.png";
import Integrations from "../../images/partners/integrations.png";
import IGMockup from "../../images/mdn/IG-mockup.png";
import GMBMockup from "../../images/mdn/GMB-mockup.png";

import NicoleProfile from "../../images/testimonials/Nicole.jpeg";
import HNProfile from "../../images/testimonials/hnog.jpeg";
// import MARProfile from "../../images/testimonials/MarUG.jpeg";
import MickJProfile from "../../images/testimonials/MickJ.jpeg";
import EmmaProfile from "../../images/testimonials/Emma-spk.jpeg";

import Kanban from "../../images/description/kanban.jpg";

import NewPostVideoMobile from "../../images/description/OptimisedSpkCesam.mp4";
// import NewPostVideoMobile from "../../images/description/DemoSPKOptim.mp4";

const LandingCESAM = () => {
  const { t, i18n } = useTranslation(["landingMDN", "seo"]);

  const [kanban, setKanban] = useState([]);

  const getCompletedKanban = async () => {
    await axios
      .get(
        process.env.REACT_APP_PIKA_API_URI +
          "/kanban/get_completed_kanban_dashboard"
      )
      .then((response) => {
        setKanban(response.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    Aos.init({
      offset: 300,
    });

    getCompletedKanban();

    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet
        title={t("seo_landing_title", { ns: "seo" })}
        htmlAttributes={{ lang: "en" }}
        meta={[
          {
            property: "twitter:card",
            content: "summary_large_image",
          },
          {
            property: "twitter:site",
            content: "@social_pika",
          },
          {
            property: "twitter:title",
            content: t("seo_landing_title", { ns: "seo" }),
          },
          {
            property: "twitter:description",
            content: t("seo_landing_description", { ns: "seo" }),
          },
          {
            property: "twitter:image",
            content:
              "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png",
          },
          {
            property: "og:url",
            content: "https://www.socialpika.com",
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            property: "og:title",
            content: t("seo_landing_title", { ns: "seo" }),
          },
          {
            property: "og:description",
            content: t("seo_landing_description", { ns: "seo" }),
          },
          {
            property: "og:image",
            content:
              "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png",
          },
          {
            property: "fb:app_id",
            content: process.env.REACT_APP_FB_APP_ID,
          },
        ]}
      >
        <link rel="canonical" href="https://www.socialpika.com/" />
      </Helmet>
      <HeaderMDN />
      <div className="hero content_section">
        <div className="col col-maxed herotxt flex-1">
          <div className="arrow-hero">
            <img src={SPKHeroArrow} className="img-render img-fluid" alt="" />
          </div>
          {/* <h1>{t('automate')}<br />{t('your_sm')}<br />{t('save_time')}.</h1> */}
          <h1>
            {t("boost_your")}
            <br />
            <span className="grad-blue">{t("your_productivity")}</span>
            <br />
            {t("on_sm")}.
          </h1>
          <p className="p-large">
            {t("save_hours_landing")}
            <br />
            {t("grow_your_presence")}
            <br />
            {t("community")}
          </p>
          <div className="cta-block margin-t4">
            <div className="row vcentered row_nowrap">
              <a className="cta" href="https://app.socialpika.com/signup">
                {t("cta_title")}
              </a>
              <span className="cta_sub">
                {t("cta_subtitle_1")}
                <br />
                {t("cta_subtitle_2")}
              </span>
            </div>
            <div className="cta_demo">
              {t("or")} <Link to="/demo">{t("book_demo")}</Link>
            </div>
          </div>
        </div>
        <div className="col heroimg flex-1">
          <img
            src={SPKHero}
            className="img-render img-fluid"
            alt={t("hero_img_alt")}
          />
        </div>
      </div>
      <div className="black-bg">
        <div className="dividers margin-t4">
          <h3 className="text-centered text-white margin-t2">
            {t("join_businesses")}
          </h3>
          <div className="row content_section margin-t4">
            <img src={PartnerAvis} alt="Avis" />
            <img src={PartnerPDS} alt="Panier des Sens" />
            <img src={PartnerLB} alt="Lemon Bytes" />
            <img src={PartnerPS} alt="Preference Search" />
            {/* <img src={Partner7} alt="Preference Search" /> */}
          </div>
          <div className="row margin-t2 margin-b2">
            <div className="review">
              <div className="quote">
                "It's very easy to use. Clean lay-out and superb support (if you
                need it). All features are available for saving time and money
                compared to other social media software."
              </div>
              <div className="stars">
                Capterra
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <div className="review-date">(Jan, 2023)</div>
              </div>
            </div>
            <div className="review">
              <div className="quote">
                "One of the best social media posting tool. Clean UI, analytics
                and easy to use features for daily social media postings. Lets
                you create social media post for multiple platform within a few
                minutes."
              </div>
              <div className="stars">
                G2
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <svg viewBox="0 0 24 24" className="svg-2">
                  <path
                    fill="current"
                    d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                  />
                </svg>
                <div className="review-date">(Nov, 2022)</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content_section">
        <div className="row vcentered padding-t8">
          <div data-aos="fade-right" className="col column-flex-start flex-3">
            <h2>
              {t("create_posts")}{" "}
              <span className="grad-blue">{t("multiple_platforms")}</span>{" "}
              {t("in_seconds")}
            </h2>
            <p className="margin-t4">{t("create_posts_desc")}</p>
          </div>
          <div className="flex-1"></div>
          <div data-aos="fade-left" className="flex-3">
            <img
              src={PostCreation}
              className="img-render img-fluid"
              alt={t("ana_img_alt")}
            />
          </div>
        </div>
      </div>
      <div className="content_section">
        <div className="row vcentered padding-t8">
          <div data-aos="fade-right" className="flex-3">
            <img
              src={ContentAI}
              className="img-render img-fluid"
              alt={t("ana_img_alt")}
            />
          </div>

          <div className="flex-1"></div>
          <div data-aos="fade-left" className="col column-flex-start flex-3">
            <h2>
              <span className="grad-blue">{t("save_hours")}</span>{" "}
              {t("generating_posts_ads")}{" "}
              <span className="grad-blue">{t("ai")}</span>
            </h2>
            <p className="margin-t4">{t("ai_desc")}</p>
          </div>
        </div>
      </div>
      <div className="content_section">
        <div className="row vcentered padding-t8">
          <div data-aos="fade-right" className="col column-flex-start flex-3">
            <h2>
              {t("ensure")} <span className="grad-blue">{t("presence")}</span>{" "}
              {t("online_by")}{" "}
              <span className="grad-blue">{t("scheduling")}</span>{" "}
              {t("your_posts")}.
            </h2>
            <p className="margin-t4">{t("presence_desc")}</p>
          </div>
          <div className="flex-1"></div>
          <div data-aos="fade-left" className="flex-3">
            <img
              src={ScheduleImg}
              className="img-render img-fluid"
              alt={t("ana_img_alt")}
            />
          </div>
        </div>
      </div>
      <div className="content_section">
        <div className="row vcentered padding-t8">
          <div data-aos="fade-right" className="flex-3">
            <img
              src={Analytics}
              className="img-render img-fluid"
              alt={t("ana_img_alt")}
            />
          </div>
          <div className="flex-1"></div>
          <div data-aos="fade-left" className="col column-flex-start flex-3">
            <h2>
              <span className="grad-blue">{t("see_what")}</span>
              {t("repeat")}
            </h2>
            <p className="margin-t4">{t("get_analytics")}</p>
          </div>
        </div>
      </div>
      <div className="content_section">
        <div className="row vcentered padding-t8">
          <div data-aos="fade-right" className="col column-flex-start flex-3">
            <h2>
              <span className="grad-blue">{t("engage")}</span>{" "}
              {t("with_your_community")}{" "}
              <span className="grad-blue">{t("one_place")}</span>
            </h2>
            <p className="margin-t4">{t("engage_desc")}</p>
          </div>
          <div className="flex-1"></div>
          <div data-aos="fade-left" className="flex-3">
            <img
              src={Engage}
              className="img-render img-fluid"
              alt={t("engage_img_alt")}
            />
          </div>
        </div>
      </div>
      <div className="main-video">
        <div className="content_section margin-t8">
          {/* {!isMobile ?
                        <video autoPlay loop muted>
                            <source src={NewPostVideo} type="video/webm" />
                        </video>
                        : */}
          <div
            dangerouslySetInnerHTML={{
              __html: `<video autoplay loop muted playsinline>
      <source src=${NewPostVideoMobile} type="video/mp4" />
      Your browser does not support the video tag.
</video>`,
            }}
          />
        </div>
      </div>
      <div className="black-bg">
        <div className="content_section margin-0">
          <div className="row vcentered padding-t8 reverse_wrap">
            <div
              data-aos="fade-right"
              className="col column-flex-start flex-3 margin-b1"
            >
              <h2 className="text-white">
                {t("create")}{" "}
                <span className="grad-blue">{t("rich_content")}</span>{" "}
                {t("posts_its")}
              </h2>
              <p className="text-light-white margin-t4">{t("import_from")}</p>
              <a
                href="https://www.canva.com/your-apps/AAEvEEWVDlE-socialpika"
                className="margin-t2 learn_more learn_more_white"
                target="_blank"
                rel="noreferrer"
              >
                {t("learn_more")}
              </a>
            </div>
            <div className="flex-1"></div>
            <div className="flex-3">
              <img
                src={Integrations}
                className="img-render img-fluid"
                alt={t("import_img_alt")}
              />
            </div>
          </div>
        </div>
        <div className="content_section">
          <div className="row vcentered padding-t8 reverse_wrap">
            <div className="flex-3">
              <img
                src={IGMockup}
                className="img-render img-fluid"
                alt={t("tag_alt")}
              />
            </div>
            <div className="flex-1"></div>
            <div
              data-aos="fade-right"
              className="col column-flex-start flex-3 margin-b1"
            >
              <h2 className="text-white">
                <span className="grad-blue">{t("tag")}</span>{" "}
                {t("people_n_orga")}
              </h2>
              <p className="text-light-white margin-t4">
                {t("tag_people_orga")}
              </p>
            </div>
          </div>
        </div>
        <div className="content_section">
          <div className="row vcentered padding-t8 reverse_wrap">
            <div
              data-aos="fade-right"
              className="col column-flex-start flex-3 margin-b1"
            >
              <h2 className="text-white">
                <span className="grad-blue">{t("boost")}</span>{" "}
                {t("local_presence")}
              </h2>
              <p className="text-light-white margin-t4">{t("gmb_subtitle")}</p>
            </div>
            <div className="flex-1"></div>
            <div className="flex-3">
              <img
                src={GMBMockup}
                className="img-render img-fluid"
                alt={t("import_img_alt")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="features_section padding-b2">
        <div className="content_section">
          <div className="col centered grey-bg margin-t8">
            <h3 className="text-centered padding-s2">{t("smart_features")}</h3>
            <div className="row centered padding-s4">
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"
                  />
                </svg>
                <h4>{t("sf_preview")}</h4>
                <p>{t("sf_preview_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M9 39h2.2l22.15-22.15-2.2-2.2L9 36.8Zm30.7-24.3-6.4-6.4 2.1-2.1q.85-.85 2.1-.85t2.1.85l2.2 2.2q.85.85.85 2.1t-.85 2.1Zm-2.1 2.1L12.4 42H6v-6.4l25.2-25.2Zm-5.35-1.05-1.1-1.1 2.2 2.2Z"
                  />
                </svg>
                <h4>{t("sf_one_composer")}</h4>
                <p>{t("sf_one_composer_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="m9.35 20.45 5.3 2.25q.9-1.8 1.925-3.55Q17.6 17.4 18.75 15.8L14.8 15Zm7.7 4.05 6.65 6.65q2.85-1.3 5.35-2.95 2.5-1.65 4.05-3.2 4.05-4.05 5.95-8.3 1.9-4.25 2.05-9.6-5.35.15-9.6 2.05t-8.3 5.95q-1.55 1.55-3.2 4.05-1.65 2.5-2.95 5.35Zm11.45-4.8q-1-1-1-2.475t1-2.475q1-1 2.475-1t2.475 1q1 1 1 2.475t-1 2.475q-1 1-2.475 1t-2.475-1Zm-.75 19.15 5.45-5.45-.8-3.95q-1.6 1.15-3.35 2.175T25.5 33.55Zm16.3-34.7q.45 6.8-1.7 12.4-2.15 5.6-7.1 10.55l-.1.1-.1.1 1.1 5.5q.15.75-.075 1.45-.225.7-.775 1.25l-8.55 8.6-4.25-9.9-8.5-8.5-9.9-4.25 8.6-8.55q.55-.55 1.25-.775.7-.225 1.45-.075l5.5 1.1q.05-.05.1-.075.05-.025.1-.075 4.95-4.95 10.55-7.125 5.6-2.175 12.4-1.725Zm-36.6 27.6Q9.2 30 11.725 29.975 14.25 29.95 16 31.7q1.75 1.75 1.725 4.275Q17.7 38.5 15.95 40.25q-1.3 1.3-4.025 2.15Q9.2 43.25 3.75 44q.75-5.45 1.575-8.2.825-2.75 2.125-4.05Zm2.1 2.15q-.7.75-1.25 2.35t-.95 4.1q2.5-.4 4.1-.95 1.6-.55 2.35-1.25.95-.85.975-2.125.025-1.275-.875-2.225-.95-.9-2.225-.875-1.275.025-2.125.975Z"
                  />
                </svg>
                <h4>{t("sf_ai_power")}</h4>
                <p>{t("sf_ai_power_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
                  />
                </svg>
                <h4>{t("sf_reel")}</h4>
                <p>{t("sf_reel_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 512 512">
                  <path
                    fill="current"
                    d="M412.19,118.66a109.27,109.27,0,0,1-9.45-5.5,132.87,132.87,0,0,1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14,23.9,350,16,350.13,16H267.69V334.78c0,4.28,0,8.51-.18,12.69,0,.52-.05,1-.08,1.56,0,.23,0,.47-.05.71,0,.06,0,.12,0,.18a70,70,0,0,1-35.22,55.56,68.8,68.8,0,0,1-34.11,9c-38.41,0-69.54-31.32-69.54-70s31.13-70,69.54-70a68.9,68.9,0,0,1,21.41,3.39l.1-83.94a153.14,153.14,0,0,0-118,34.52,161.79,161.79,0,0,0-35.3,43.53c-3.48,6-16.61,30.11-18.2,69.24-1,22.21,5.67,45.22,8.85,54.73v.2c2,5.6,9.75,24.71,22.38,40.82A167.53,167.53,0,0,0,115,470.66v-.2l.2.2C155.11,497.78,199.36,496,199.36,496c7.66-.31,33.32,0,62.46-13.81,32.32-15.31,50.72-38.12,50.72-38.12a158.46,158.46,0,0,0,27.64-45.93c7.46-19.61,9.95-43.13,9.95-52.53V176.49c1,.6,14.32,9.41,14.32,9.41s19.19,12.3,49.13,20.31c21.48,5.7,50.42,6.9,50.42,6.9V131.27C453.86,132.37,433.27,129.17,412.19,118.66Z"
                  />
                </svg>

                <h4>{t("sf_tiktok")}</h4>
                <p>{t("sf_tiktok_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                {/* <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                                </svg> */}
                <svg viewBox="0 0 681 645" fill="none">
                  <path
                    d="M90.2238 87L284.406 346.644L89 557.74H132.982L304.063 372.918L442.287 557.74H591.948L386.835 283.495L568.72 87H524.739L367.187 257.212L239.884 87H90.2238ZM154.901 119.402H223.654L527.262 525.347H458.509L154.901 119.402Z"
                    fill="current"
                  />
                </svg>
                <h4>{t("sf_thread")}</h4>
                <p>{t("sf_thread_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M22 8.5C22 9.87 20.88 11 19.5 11S17 9.87 17 8.5C17 9.87 15.88 11 14.5 11C13.12 11 12 9.87 12 8.5C12 9.87 10.88 11 9.5 11S7 9.87 7 8.5C7 9.87 5.88 11 4.5 11S2 9.87 2 8.5L3.39 3.08C3.39 3.08 3.68 2 4.7 2H19.3C20.32 2 20.61 3.08 20.61 3.08L22 8.5M21 12.2V20C21 21.1 20.1 22 19 22H5C3.9 22 3 21.1 3 20V12.2C3.46 12.39 3.97 12.5 4.5 12.5C5.45 12.5 6.32 12.17 7 11.62C7.69 12.17 8.56 12.5 9.5 12.5C10.45 12.5 11.32 12.17 12 11.62C12.69 12.17 13.56 12.5 14.5 12.5C15.45 12.5 16.32 12.17 17 11.62C17.68 12.17 18.56 12.5 19.5 12.5C20.03 12.5 20.54 12.39 21 12.2M19 17.33C19 17.13 19 16.92 18.95 16.7L18.92 16.54H15.95V17.71H17.76C17.7 17.93 17.62 18.15 17.45 18.33C17.12 18.66 16.67 18.84 16.19 18.84C15.69 18.84 15.2 18.63 14.84 18.28C14.15 17.57 14.15 16.42 14.86 15.7C15.55 15 16.69 15 17.41 15.67L17.55 15.8L18.39 14.95L18.23 14.81C17.67 14.29 16.93 14 16.15 14H16.14C15.33 14 14.57 14.31 14 14.87C13.41 15.45 13.08 16.21 13.08 17C13.08 17.8 13.39 18.54 13.96 19.09H13.96C14.54 19.66 15.35 20 16.18 20H16.2C17 20 17.71 19.71 18.23 19.2C18.7 18.72 19 18 19 17.33Z"
                  />
                </svg>
                <h4>{t("sf_gmb")}</h4>
                <p>{t("sf_gmb_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M9.04,21.54C10,21.83 10.97,22 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2A10,10 0 0,0 2,12C2,16.25 4.67,19.9 8.44,21.34C8.35,20.56 8.26,19.27 8.44,18.38L9.59,13.44C9.59,13.44 9.3,12.86 9.3,11.94C9.3,10.56 10.16,9.53 11.14,9.53C12,9.53 12.4,10.16 12.4,10.97C12.4,11.83 11.83,13.06 11.54,14.24C11.37,15.22 12.06,16.08 13.06,16.08C14.84,16.08 16.22,14.18 16.22,11.5C16.22,9.1 14.5,7.46 12.03,7.46C9.21,7.46 7.55,9.56 7.55,11.77C7.55,12.63 7.83,13.5 8.29,14.07C8.38,14.13 8.38,14.21 8.35,14.36L8.06,15.45C8.06,15.62 7.95,15.68 7.78,15.56C6.5,15 5.76,13.18 5.76,11.71C5.76,8.55 8,5.68 12.32,5.68C15.76,5.68 18.44,8.15 18.44,11.43C18.44,14.87 16.31,17.63 13.26,17.63C12.29,17.63 11.34,17.11 11,16.5L10.33,18.87C10.1,19.73 9.47,20.88 9.04,21.57V21.54Z"
                  />
                </svg>
                <h4>{t("sf_pins")}</h4>
                <p>{t("sf_pins_desc")}</p>
              </div>

              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M6.18,15.64A2.18,2.18 0 0,1 8.36,17.82C8.36,19 7.38,20 6.18,20C5,20 4,19 4,17.82A2.18,2.18 0 0,1 6.18,15.64M4,4.44A15.56,15.56 0 0,1 19.56,20H16.73A12.73,12.73 0 0,0 4,7.27V4.44M4,10.1A9.9,9.9 0 0,1 13.9,20H11.07A7.07,7.07 0 0,0 4,12.93V10.1Z"
                  />
                </svg>
                <h4>{t("sf_rss")}</h4>
                <p>{t("sf_rss_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M12,15C12.81,15 13.5,14.7 14.11,14.11C14.7,13.5 15,12.81 15,12C15,11.19 14.7,10.5 14.11,9.89C13.5,9.3 12.81,9 12,9C11.19,9 10.5,9.3 9.89,9.89C9.3,10.5 9,11.19 9,12C9,12.81 9.3,13.5 9.89,14.11C10.5,14.7 11.19,15 12,15M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12V13.45C22,14.45 21.65,15.3 21,16C20.3,16.67 19.5,17 18.5,17C17.3,17 16.31,16.5 15.56,15.5C14.56,16.5 13.38,17 12,17C10.63,17 9.45,16.5 8.46,15.54C7.5,14.55 7,13.38 7,12C7,10.63 7.5,9.45 8.46,8.46C9.45,7.5 10.63,7 12,7C13.38,7 14.55,7.5 15.54,8.46C16.5,9.45 17,10.63 17,12V13.45C17,13.86 17.16,14.22 17.46,14.53C17.76,14.84 18.11,15 18.5,15C18.92,15 19.27,14.84 19.57,14.53C19.87,14.22 20,13.86 20,13.45V12C20,9.81 19.23,7.93 17.65,6.35C16.07,4.77 14.19,4 12,4C9.81,4 7.93,4.77 6.35,6.35C4.77,7.93 4,9.81 4,12C4,14.19 4.77,16.07 6.35,17.65C7.93,19.23 9.81,20 12,20H17V22H12C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z"
                  />
                </svg>
                <h4>{t("tags_co")}</h4>
                <p>{t("tags_co_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M3,5A2,2 0 0,1 5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5M7,18H9L9.35,16H13.35L13,18H15L15.35,16H17.35L17.71,14H15.71L16.41,10H18.41L18.76,8H16.76L17.12,6H15.12L14.76,8H10.76L11.12,6H9.12L8.76,8H6.76L6.41,10H8.41L7.71,14H5.71L5.35,16H7.35L7,18M10.41,10H14.41L13.71,14H9.71L10.41,10Z"
                  />
                </svg>
                <h4>{t("tags_group")}</h4>
                <p>{t("tags_group_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M6 36v-3h12v3Zm0-10.5v-3h24v3ZM6 15v-3h36v3Z"
                  />
                </svg>
                <h4>{t("sf_signatures")}</h4>
                <p>{t("sf_signatures_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M12 28h24v-3H12Zm0-6.5h24v-3H12Zm0-6.5h24v-3H12Zm32 29-8-8H7q-1.15 0-2.075-.925Q4 34.15 4 33V7q0-1.15.925-2.075Q5.85 4 7 4h34q1.2 0 2.1.925Q44 5.85 44 7ZM7 7v26h30.25L41 36.75V7H7Zm0 0v29.75V7Z"
                  />
                </svg>
                <h4>{t("sf_first_comment")}</h4>
                <p>{t("sf_first_comment_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M16,11.78L20.24,4.45L21.97,5.45L16.74,14.5L10.23,10.75L5.46,19H22V21H2V3H4V17.54L9.5,8L16,11.78Z"
                  />
                </svg>
                <h4>{t("tags_performance")}</h4>
                <p>{t("tags_performance_desc")}</p>
              </div>

              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M5 3A2 2 0 0 0 3 5H5M7 3V5H9V3M11 3V5H13V3M15 3V5H17V3M19 3V5H21A2 2 0 0 0 19 3M3 7V9H5V7M7 7V11H11V7M13 7V11H17V7M19 7V9H21V7M3 11V13H5V11M19 11V13H21V11M7 13V17H11V13M13 13V17H17V13M3 15V17H5V15M19 15V17H21V15M3 19A2 2 0 0 0 5 21V19M7 19V21H9V19M11 19V21H13V19M15 19V21H17V19M19 19V21A2 2 0 0 0 21 19Z"
                  />
                </svg>
                <h4>{t("sf_groups")}</h4>
                <p>{t("sf_groups_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z"
                  />
                </svg>
                <h4>{t("sf_team")}</h4>
                <p>{t("sf_team_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="m12 42-7.65-7.65L12 26.7l2.45 2.45L11 32.6h26l-3.45-3.45L36 26.7l7.65 7.65L36 42l-2.45-2.45L37 36.1H11l3.45 3.45ZM4.95 21.25v-.65q0-1.05.575-1.925T7.1 17.35q1.25-.55 2.575-.825Q11 16.25 12.45 16.25t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm23.1 0v-.65q0-1.05.575-1.925T30.2 17.35q1.25-.55 2.575-.825 1.325-.275 2.775-.275t2.775.275q1.325.275 2.575.825 1 .45 1.575 1.325t.575 1.925v.65Zm-15.6-7.75q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q10.9 6 12.45 6 14 6 15.1 7.1q1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Zm23.1 0q-1.55 0-2.65-1.1-1.1-1.1-1.1-2.65 0-1.55 1.1-2.65Q34 6 35.55 6q1.55 0 2.65 1.1 1.1 1.1 1.1 2.65 0 1.55-1.1 2.65-1.1 1.1-2.65 1.1Z"
                  />
                </svg>
                <h4>{t("sf_third_party")}</h4>
                <p>{t("sf_third_party_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M9,10V12H7V10H9M13,10V12H11V10H13M17,10V12H15V10H17M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5A2,2 0 0,1 5,3H6V1H8V3H16V1H18V3H19M19,19V8H5V19H19M9,14V16H7V14H9M13,14V16H11V14H13M17,14V16H15V14H17Z"
                  />
                </svg>
                <h4>{t("sf_monthly_cal")}</h4>
                <p>{t("sf_monthly_cal_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 24 24">
                  <path
                    fill="current"
                    d="M6 1H8V3H16V1H18V3H19C20.11 3 21 3.9 21 5V19C21 20.11 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H6V1M5 8V19H19V8H5M7 10H17V12H7V10Z"
                  />
                </svg>
                <h4>{t("sf_weekly_cal")}</h4>
                <p>{t("sf_weekly_cal_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M35.8 42H13.6V16.4L27.5 2l1.95 1.55q.3.25.45.7.15.45.15 1.1v.5L27.8 16.4h14.95q1.2 0 2.1.9.9.9.9 2.1v4.1q0 .35.075.725t-.075.725l-6.3 14.5q-.45 1.05-1.475 1.8Q36.95 42 35.8 42Zm-19.2-3h19.85l6.3-14.95V19.4H24.1l2.65-12.45-10.15 10.7Zm0-21.35V39Zm-3-1.25v3H6.95V39h6.65v3H3.95V16.4Z"
                  />
                </svg>
                <h4>{t("sf_post_analytics")}</h4>
                <p>{t("sf_post_analytics_desc")}</p>
              </div>
              <div data-aos="zoom-in" className="staticcard">
                <svg viewBox="0 0 48 48">
                  <path
                    fill="current"
                    d="M6 42v-3.8l3-3V42Zm8.25 0V30.2l3-3V42Zm8.25 0V27.2l3 3.05V42Zm8.25 0V30.25l3-3V42ZM39 42V22.2l3-3V42ZM6 30.2v-4.25l14-13.9 8 8L42 6v4.25L28 24.3l-8-8Z"
                  />
                </svg>
                <h4>{t("sf_acc_analytics")}</h4>
                <p>{t("sf_acc_analytics_desc")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="black-bg padding-b4 testimonials_section">
        <div className="content_section">
          <div className="row vcentered padding-t8 reverse_wrap">
            <div className="col column-flex-start flex-1">
              <h2 className="text-white">
                {t("join_list")} <span className="grad-blue">{t("happy")}</span>{" "}
                {t("spk_customers")}
              </h2>
              <div className="cta-block margin-t4">
                <a className="cta" href="https://app.socialpika.com/signup">
                  {t("cta_title")}
                </a>
              </div>
            </div>
            <div className="carousel_wrapper flex-1">
              <Carousel
                showArrows={false}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
                autoPlay={true}
                interval={8000}
              >
                <div>
                  <img src={EmmaProfile} alt="" />
                  <div className="myCarousel">
                    <div className="stars">
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                    </div>
                    <p>{t("testimonial_emma")}</p>
                    <h3>Emma C.</h3>
                    <h4>Content Manager</h4>
                  </div>
                </div>
                <div>
                  <img src={NicoleProfile} alt="" />
                  <div className="myCarousel">
                    <div className="stars">
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                    </div>
                    <p>{t("testimonial_nic")}</p>
                    <h3>Nicole</h3>
                    <h4>{t("position_nic")}</h4>
                  </div>
                </div>
                <div>
                  <img src={MickJProfile} alt="" />
                  <div className="myCarousel">
                    <div className="stars">
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                    </div>
                    <p>{t("testimonial_mj")}</p>
                    <h3>Michael J.</h3>
                    <h4>{t("position_mj")}</h4>
                  </div>
                </div>
                <div>
                  <img src={HNProfile} alt="" />
                  <div className="myCarousel">
                    <div className="stars">
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                      <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                        <path
                          fill="current"
                          d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                        />
                      </svg>
                    </div>
                    <p>{t("testimonial_hn")}</p>
                    <h3>Hélène G.</h3>
                    <h4>{t("position_hn")}</h4>
                  </div>
                </div>
                {/* <div>
                                    <img src={MARProfile} alt="" />
                                    <div className="myCarousel">
                                        <div className='stars'>
                                            <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                                                <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                                            </svg>
                                            <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                                                <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                                            </svg>
                                            <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                                                <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                                            </svg>
                                            <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                                                <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                                            </svg>
                                            <svg viewBox="0 0 24 24" className="svg-2 svg-blue">
                                                <path fill="current" d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z" />
                                            </svg>
                                        </div>
                                        <p>{t('testimonial_mg')}</p>
                                        <h3>Mariko G.</h3>
                                        <h4>{t('position_mg')}</h4>
                                    </div>
                                </div> */}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <div className="content_section">
        <div className="row padding-t8 reverse_wrap">
          <div data-aos="fade-right" className="col column-flex-start flex-3">
            {kanban && kanban.length > 0 ? (
              <ul className="kanban_dashboard">
                {kanban.map((task, i) => {
                  return (
                    <li key={i}>
                      <div className="row row_spaced nowrap">
                        <div>
                          <div className="kanban_task">{task.task}</div>
                          <div className="kanban_description">
                            {i18n.language === "fr"
                              ? task.task_desc_fr
                              : task.task_desc_en}
                          </div>
                          <div className="kanban_status">
                            <div className="kanban_status_block">
                              <svg viewBox="0 0 24 24">
                                <path
                                  fill="current"
                                  d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
                                />
                              </svg>
                              {t("kanban_done")},{" "}
                              {moment(task.updatedAt).format("DD MMMM YYYY")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            ) : (
              ""
            )}
          </div>
          <div className="flex-1"></div>
          <div data-aos="fade-left" className="col column-flex-start flex-3">
            <h2>
              <span className="grad-blue">#BuildInPublic</span>:{" "}
              {t("your_product")}
            </h2>
            <img
              className="img-fluid img-margin img-min img-rounded margin-t2"
              src={Kanban}
              alt={t("evolutive_header")}
            />
            <p className="margin-t2">{t("your_product_vote")}</p>
            <Link to="/buildinpublic" className="margin-t2 learn_more">
              {t("full_list")}
            </Link>
          </div>
        </div>
      </div>
      <div className="content_section margin-t4">
        <div className="row padding-t8">
          <div className="col column-flex-start flex-3">
            <h2>
              {t("questions_requests")}{" "}
              <span className="grad-blue">{t("talk")}</span>.
            </h2>
            <p className="margin-t4">{t("answer_faq")}</p>
            <Link to="/faq" className="margin-t2 learn_more">
              {t("more_faq")}
            </Link>
          </div>
          <div className="flex-1"></div>
          <div data-aos="fade-top" className="col column-flex-start flex-3">
            <FaqMDN />
          </div>
        </div>
      </div>
      <FooterMDN />
    </div>
  );
};

export default LandingCESAM;
