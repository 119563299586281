import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from 'moment'
import { Helmet } from "react-helmet";


import HeaderMDN from '../Header/HeaderMDN';
import FooterMDN from '../Header/FooterMDN';

import "./blog.css";


const Blog = () => {

    const history = useHistory();
    const [articles, setArticles] = useState([]);
    const { t, i18n } = useTranslation(['blog', 'seo']);

    const viewArticle = (article_id) => {
        const post = articles.filter((element) => element._id === article_id);
        history.push({
            pathname: '/blog/' + post[0].post_url,
            state: { post: post }
        });
    }

    const getBlogArticles = async () => {

        const payload = {
            language: i18n.language
        }


        await axios
            .post(process.env.REACT_APP_PIKA_API_URI + "/blog/get_published_articles", payload)
            .then((response) => {
                setArticles(response.data);
            })
            .catch(() => {
            });

    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getBlogArticles();
    }, [i18n.language])

    return (
        <div>
            <Helmet title={t('seo_blog_title', { ns: 'seo' })}
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        property: "twitter:card",
                        content: "summary_large_image"
                    },
                    {
                        property: "twitter:site",
                        content: "@social_pika"
                    },
                    {
                        property: "twitter:title",
                        content: t('seo_blog_title', { ns: 'seo' })
                    },
                    {
                        property: "twitter:description",
                        content: t('seo_blog_description', { ns: 'seo' })
                    },
                    {
                        property: "twitter:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "og:url",
                        content: "https://www.socialpika.com/blog"
                    },
                    {
                        property: "og:type",
                        content: "website"
                    },
                    {
                        property: "og:title",
                        content: t('seo_blog_title', { ns: 'seo' })
                    },
                    {
                        property: "og:description",
                        content: t('seo_blog_description', { ns: 'seo' })
                    },
                    {
                        property: "og:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "fb:app_id",
                        content: process.env.REACT_APP_FB_APP_ID
                    }
                ]}  >
                <link rel="canonical" href="https://www.socialpika.com/blog" />
            </Helmet>
            <HeaderMDN />
            <div className='content_section width-800'>
                <div className='col centered blog-hero'>
                    <h1>Blog</h1>
                    <p>{t('blog_description')}</p>
                </div>
            </div>
            <section>
                <div className='row padding-s2 margin-t4'>
                    {(articles && articles.length > 0) ?
                        articles.map((article, i) => {
                            return (
                                <div key={i} className='blog-card' onClick={() => viewArticle(article._id)}>
                                    <div className='blog-card-photo'>
                                        <img src={article.main_img_url} alt={article.subtitle} />
                                    </div>
                                    <div className='blog-card-content'>
                                        <div className='header'>{article.category}</div>
                                        <h4>{article.title}</h4>
                                        <div className='date'>{moment(article.publication_date).format("DD MMMM YYYY") || '---'}</div>
                                    </div>
                                </div>
                            )
                        })
                        : ""}
                </div>
            </section>
            <FooterMDN />
        </div>
    )
}

export default Blog







