import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom";

import DocHeader from './DocsComponents/DocHeader';

import "./docs.css"

const Docs = (props) => {

    const history = useHistory()
    const { mainCat, subCat } = useParams();

    const [helpMenu, setHelpMenu] = useState([
        {
            order: 0,
            name: 'Quick Start',
            language: 'en',
            active: false,
            path: '/get-started',
            submenu: [{
                language: 'en',
                name: 'Introduction to SPK',
                path: '/post-in-2-minutes'
            }]
        },
        {
            order: 1,
            name: 'Social Media Accounts',
            active: false,
            path: '/social-media-accounts',
            submenu: [
                {
                    order: 0,
                    language: 'en',
                    name: 'Add/Remove accounts',
                    path: '/manage-accounts',
                    active: false
                },
                {
                    order: 1,
                    language: 'en',
                    name: 'Instagram accounts',
                    path: '/instagram-accounts',
                    active: false
                }
            ]
        },
        {
            order: 2,
            name: 'Create Posts',
            language: 'en',
            active: false,
            path: '/create-posts',
            submenu: [{
                order: 0,
                language: 'en',
                name: 'Regular Posts',
                path: '/regular-posts'
            },
            {
                order: 1,
                language: 'en',
                name: 'Pins',
                path: '/pins'
            },
            {
                order: 2,
                language: 'en',
                name: 'Twitter Thread',
                path: '/thread'
            },
            {
                order: 3,
                language: 'en',
                name: 'Bulk Posting',
                path: '/bulk_posting'
            },
            {
                order: 4,
                language: 'en',
                name: 'Posts from RSS',
                path: '/rss_posts'
            },
            {
                order: 5,
                language: 'en',
                name: 'Hashtags',
                path: '/hashtags'
            },
            {
                order: 6,
                language: 'en',
                name: 'Tag people, products',
                path: '/tag'
            },
            {
                order: 7,
                language: 'en',
                name: 'Text variations',
                path: '/text_variations'
            },
            ]
        },
        {
            order: 3,
            name: 'Using Media',
            language: 'en',
            active: false,
            path: '/media',
            submenu: [{
                order: 0,
                language: 'en',
                name: 'Attach medias to post',
                path: '/attach_media_to_post'
            },
            {
                order: 1,
                language: 'en',
                name: 'Media Library',
                path: '/media_library'
            },
            {
                order: 2,
                language: 'en',
                name: 'Upload Photos',
                path: '/photos'
            },
            {
                order: 3,
                language: 'en',
                name: 'Upload Videos',
                path: '/videos'
            },
            {
                order: 4,
                language: 'en',
                name: 'Import from Canva',
                path: '/videos'
            },
            {
                order: 5,
                language: 'en',
                name: 'Edit Photos',
                path: '/photo_editing'
            }
            ]
        },
    ]);

    const toggleMenu = (id, css_state) => {
        setHelpMenu(helpMenu.map(element => element.order === id ? { ...element, active: !css_state } : { ...element, active: false }));
    };

    const toggleSubMenu = (main_index, sub_index, css_state, new_path) => {

        history.push(`/docs` + new_path);

        const sub_array_index = helpMenu.findIndex(element => element.order === main_index);

        if (sub_array_index === -1) return;
        const sub_array = helpMenu[sub_array_index].submenu;


        const updated_sub_array = sub_array.map(sub_element => sub_element.order === sub_index ? { ...sub_element, active: !css_state } : sub_element);

        const updated_array = {
            ...helpMenu[sub_array_index],
            submenu: updated_sub_array
        }

        const updated_full_array = [
            ...helpMenu.slice(0, sub_array_index),
            updated_array,
            ...helpMenu.slice(sub_array_index + 1, helpMenu.length),
        ];

        setHelpMenu([...updated_full_array]);
    };

    const checkURI = () => {
        const { mainCat, subCat } = props.match.params;
        if (!mainCat && !subCat) return;

        const mainCatIndex = helpMenu.findIndex(element => element.path === '/' + mainCat);
        if (mainCatIndex === -1) return;

        const subCatIndex = helpMenu[mainCatIndex].submenu.findIndex(element => element.path === '/' + subCat);
        if (subCatIndex === -1) return;

        const sub_index = helpMenu[mainCatIndex].submenu[subCatIndex].index;

        const updated_sub_array = helpMenu[mainCatIndex].submenu.map(sub_element => sub_element.index === sub_index ? { ...sub_element, active: true } : sub_element);

        const updated_array = {
            ...helpMenu[mainCatIndex],
            active: true,
            submenu: updated_sub_array
        }

        const updated_full_array = [
            ...helpMenu.slice(0, mainCatIndex),
            updated_array,
            ...helpMenu.slice(mainCatIndex + 1, helpMenu.length),
        ];

        setHelpMenu([...updated_full_array]);
    }

    useEffect(() => {
        checkURI();
    }, [])


    return (
        <div className='docs_grid'>
            <div className='docs_grid_header'><DocHeader /></div>
            <div className='docs_grid_menu'>
                <ul>
                    {helpMenu && helpMenu.length > 0 ?
                        helpMenu.map(mainCat => {
                            return (
                                <li key={'main_' + mainCat.order}>
                                    <div className={mainCat.active ? "list_title active" : "list_title"} onClick={e => toggleMenu(mainCat.order, mainCat.active)}>
                                        <svg viewBox="0 0 24 24">
                                            <path fill="current" d="M8,5.14V19.14L19,12.14L8,5.14Z" />
                                        </svg>
                                        {mainCat.name}
                                    </div>
                                    <ul className={mainCat.active ? "active" : ""}>
                                        {mainCat.submenu && mainCat.submenu.length > 0 ?
                                            mainCat.submenu.map(submenu => {
                                                return (
                                                    <li key={'sub_' + submenu.order} className={submenu.active ? "active" : ""} onClick={e => toggleSubMenu(mainCat.order, submenu.order, submenu.active, mainCat.path + submenu.path)}>{submenu.name}</li>
                                                )
                                            })
                                            :
                                            <li>Nothing</li>

                                        }
                                    </ul>
                                </li>
                            )
                        })
                        : ""
                    }
                </ul>
            </div>
            <div className='docs_grid_content'>
                <div className='content'>
                    <h1>How to use SocialPika ? (quick start)</h1>
                    <h2><span className='number'>1</span> Link your Social accounts</h2>
                    <p>Head towards the Social Media Tools section of SocialPika.</p>
                </div>
            </div>
        </div>
    )
}

export default Docs