import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import '../styles/mdn_faq.css';




const FaqMDN = () => {
    const { t } = useTranslation(['faqMDN']);

    const [faq, setFaq] = useState([
        {
            question: t('q_what_is_spk'),
            answer: t('a_what_is_spk'),
            img: false,
            active: false
        },
        {
            question: t('q_what_social_networks'),
            answer: t('a_what_social_networks'),
            img: false,
            active: false
        },
        {
            question: t('q_how_many_account'),
            answer: t('a_how_many_account'),
            img: false,
            active: false
        },
        {
            question: t('q_mobile_app'),
            answer: t('a_mobile_app'),
            img: false,
            active: false
        },
        {
            question: t('q_how_many_media'),
            answer: t('a_how_many_media'),
            img: false,
            active: false
        },
        {
            question: t('q_blocked_accounts'),
            answer: t('a_blocked_accounts'),
            img: false,
            active: false
        }
    ]);

    const [structuredMiniFaq, setStructuredMiniFaq] = useState('');

    const toggleQuestion = (id, css_state) => {
        setFaq(faq.map((element, i) => i === id ? { ...element, active: !css_state } : element));
    };


    useEffect(() => {
        let main_entity = [];

        faq.map(qnr => {
            let entity = {
                "@type": "Question",
                "name": qnr.question,
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": qnr.answer
                }
            }

            main_entity.push(entity);
        })


        let structured_data = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": main_entity
        }


        // return structured_data;
        setStructuredMiniFaq(JSON.stringify(structured_data));
    }, [faq])

    return (
        <div>
            <Helmet>
                <script type="application/ld+json">{structuredMiniFaq}</script>
            </Helmet>
            {faq.map((qna, i) => {
                return (
                    <div className={qna.active ? "faq_bloc active" : "faq_bloc"} key={i} onClick={e => toggleQuestion(i, qna.active)}>
                        <div className="row vcentered row_spaced question row_nowrap">
                            <h5>{qna.question}</h5>
                            <div className="dropdown-button">
                                <svg viewBox="0 0 24 24">
                                    <path fill="current" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                                </svg>
                            </div>
                        </div>
                        <p className="answer" dangerouslySetInnerHTML={{ __html: qna.answer }}>
                        </p>
                    </div>
                )
            })}
        </div>
    )
}

export default FaqMDN