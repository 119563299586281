import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Terms from "./components/others/Terms";
import Privacy from "./components/others/Privacy";

// import LandingMDN from "./components/Home/LandingMDN";
import LandingCESAM from "./components/Home/LandingCESAM";
import DemoMDN from "./components/Home/DemoMDN";
import Blog from "./components/Blog/Blog";
import BlogArticle from "./components/Blog/BlogArticle";
import About from "./components/About/About";
import Kanban from "./components/Kanban/Kanban";
import FAQ from "./components/Home/FAQ";

// import PricingMDN from "./components/Home/PricingMDN";
import PricingCESAM from "./components/Home/PricingCESAM";
// import PricingAgency from "./components/Home/PricingAgency";

import Docs from "./components/Docs/Docs";

// import NotFound from "./components/others/NotFound";

// import "./components/styles/mdn_style.css";
import "./components/styles/cesam_style.css";


const App = () => {
  const { t } = useTranslation(['cookie']);

  Cookies.set("test", "nice");

  return (
    <Router>
      <CookieConsent
        // debug={true}
        acceptOnScroll={true}
        acceptOnScrollPercentage={20}
        buttonText={t('understand')}
        style={{ background: "#0c083a" }}
        buttonStyle={{ background: "#5BC0BE", color: "#FFFFFF", fontSize: "13px" }}
      >
        {t('main_msg')}
      </CookieConsent>
      {/* <Route path="/" exact component={LandingMDN} /> */}
      <Route path="/" exact component={LandingCESAM} />
      {/* <Route path="/pricing" exact component={PricingAgency} /> */}
      <Route path="/pricing" exact component={PricingCESAM} />
      <Route path="/demo" exact component={DemoMDN} />
      <Route path="/buildinpublic" exact component={Kanban} />
      <Route path="/about" exact component={About} />
      <Route path="/terms" exact component={Terms} />
      <Route path="/privacy" exact component={Privacy} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/:id" component={BlogArticle} />
      <Route path="/faq" exact component={FAQ} />
      <Route path="/docs" exact component={Docs} />
      <Route path="/docs/:mainCat/:subCat" component={Docs} />
      {/* <Switch>
        <Route component={NotFound} />
      </Switch> */}
    </Router>
  );
}

export default App;
