import React, { useState, useEffect } from 'react'
import axios from "axios";
import moment from 'moment'
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import HeaderMDN from '../Header/HeaderMDN';
import FooterMDN from '../Header/FooterMDN';

import '../styles/mdn_kanban.css';


const Kanban = () => {
    const { t, i18n } = useTranslation(['kanban', 'seo']);

    const [kanban, setKanban] = useState([]);


    const fetchKanban = async () => {

        await axios
            .get(process.env.REACT_APP_PIKA_API_URI + "/kanban/get_kanban_landing")
            .then((response) => {
                setKanban(response.data);
            })
            .catch(() => {
            });
    }

    const checkStatus = (status, updatedAt) => {
        switch (status) {
            case -1:
                return (
                    <>
                        <svg viewBox="0 0 24 24" className="svg-2 svg-yellow" >
                            <path fill="current" d="M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                        </svg>
                        {t('kanban_on_hold')}, {moment(updatedAt).format('DD MMMM YYYY')}
                    </>
                )
            case 0:
                return (
                    <>
                        <svg viewBox="0 0 24 24" className="svg-2 svg-grey">
                            <path fill="current" d="M20,11H23V13H20V11M1,11H4V13H1V11M13,1V4H11V1H13M4.92,3.5L7.05,5.64L5.63,7.05L3.5,4.93L4.92,3.5M16.95,5.63L19.07,3.5L20.5,4.93L18.37,7.05L16.95,5.63M12,6A6,6 0 0,1 18,12C18,14.22 16.79,16.16 15,17.2V19A1,1 0 0,1 14,20H10A1,1 0 0,1 9,19V17.2C7.21,16.16 6,14.22 6,12A6,6 0 0,1 12,6M14,21V22A1,1 0 0,1 13,23H11A1,1 0 0,1 10,22V21H14M11,18H13V15.87C14.73,15.43 16,13.86 16,12A4,4 0 0,0 12,8A4,4 0 0,0 8,12C8,13.86 9.27,15.43 11,15.87V18Z" />
                        </svg>
                        {t('kanban_requested')}, {moment(updatedAt).format('DD MMMM YYYY')}
                    </>
                )
            case 1:
                return (
                    <>
                        <svg className="svg-2 svg-blue" viewBox="0 0 24 24">
                            <path fill="current" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />
                        </svg>
                        {t('kanban_wip')}, {moment(updatedAt).format('DD MMMM YYYY')}
                    </>
                )
            case 11:
                return (
                    <>
                        <svg className="svg-2 svg-green" viewBox="0 0 24 24">
                            <path fill="current" d="M7,2V4H8V18A4,4 0 0,0 12,22A4,4 0 0,0 16,18V4H17V2H7M11,16C10.4,16 10,15.6 10,15C10,14.4 10.4,14 11,14C11.6,14 12,14.4 12,15C12,15.6 11.6,16 11,16M13,12C12.4,12 12,11.6 12,11C12,10.4 12.4,10 13,10C13.6,10 14,10.4 14,11C14,11.6 13.6,12 13,12M14,7H10V4H14V7Z" />
                        </svg>
                        {t('kanban_testing')}, {moment(updatedAt).format('DD MMMM YYYY')}
                    </>
                )
            case 2:
                return (
                    <>
                        <svg className="svg-2 svg-green" viewBox="0 0 24 24">
                            <path fill="current" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
                        </svg>
                        {t('kanban_done')}, {moment(updatedAt).format('DD MMMM YYYY')}
                    </>
                )
            default:
                return (
                    <>
                        <svg viewBox="0 0 24 24" className="svg-2 svg-yellow" >
                            <path fill="current" d="M13,2.03V2.05L13,4.05C17.39,4.59 20.5,8.58 19.96,12.97C19.5,16.61 16.64,19.5 13,19.93V21.93C18.5,21.38 22.5,16.5 21.95,11C21.5,6.25 17.73,2.5 13,2.03M11,2.06C9.05,2.25 7.19,3 5.67,4.26L7.1,5.74C8.22,4.84 9.57,4.26 11,4.06V2.06M4.26,5.67C3,7.19 2.25,9.04 2.05,11H4.05C4.24,9.58 4.8,8.23 5.69,7.1L4.26,5.67M2.06,13C2.26,14.96 3.03,16.81 4.27,18.33L5.69,16.9C4.81,15.77 4.24,14.42 4.06,13H2.06M7.1,18.37L5.67,19.74C7.18,21 9.04,21.79 11,22V20C9.58,19.82 8.23,19.25 7.1,18.37M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z" />
                        </svg>
                        {t('kanban_on_hold')}, {moment(updatedAt).format('DD MMMM YYYY')}
                    </>
                )
        }
    }

    useEffect(() => {
        fetchKanban();

        window.scrollTo(0, 0)
    }, [])


    return (
        <div>
            <Helmet title={t('seo_wip_title', { ns: 'seo' })}
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        property: "twitter:card",
                        content: "summary_large_image"
                    },
                    {
                        property: "twitter:site",
                        content: "@social_pika"
                    },
                    {
                        property: "twitter:title",
                        content: t('seo_wip_title', { ns: 'seo' })
                    },
                    {
                        property: "twitter:description",
                        content: t('seo_wip_description', { ns: 'seo' })
                    },
                    {
                        property: "twitter:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "og:url",
                        content: "https://www.socialpika.com/buildinpublic"
                    },
                    {
                        property: "og:type",
                        content: "website"
                    },
                    {
                        property: "og:title",
                        content: t('seo_wip_title', { ns: 'seo' })
                    },
                    {
                        property: "og:description",
                        content: t('seo_wip_description', { ns: 'seo' })
                    },
                    {
                        property: "og:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "fb:app_id",
                        content: process.env.REACT_APP_FB_APP_ID
                    }
                ]}  >
                <link rel="canonical" href="https://www.socialpika.com/buildinpublic" />
            </Helmet>
            <HeaderMDN />
            <div className='margin-t4 content_section'>
                <div className="col centered margin-t4">
                    <h1 className='section-heading-center'>#BuildInPublic</h1>
                    <p>{t('current_wip_sub')}</p>
                    {
                        (kanban && kanban.length > 0) ?
                            <ul className='kanban_dashboard margin-t8'>
                                {kanban.map((task, t) => {
                                    return (
                                        <li key={t}>
                                            <div className='row row_spaced nowrap'>
                                                <div>
                                                    <div className='kanban_task'>{task.task}</div>
                                                    <div className='kanban_description'>{i18n.language === "fr" ? task.task_desc_fr : task.task_desc_en}</div>
                                                    <div className='kanban_status'>

                                                        <div className='kanban_status_block'>
                                                            {checkStatus(task.status, task.updatedAt)}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </li>
                                    )
                                })

                                }
                            </ul>
                            :
                            ""
                    }
                </div>
            </div>
            <FooterMDN />
        </div>
    )
}

export default Kanban