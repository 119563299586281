import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import TimezoneSelect from 'react-timezone-select';

import HeaderMDN from '../Header/HeaderMDN'
import FooterMDN from '../Header/FooterMDN';

import DemoHero from "../../images/mdn/demo-hero.jpg";

import "../styles/mdn_demo.css";
import "../styles/mdn_loader.css";

const DemoMDN = () => {
    const { t } = useTranslation(['demoMDN', 'seo']);

    const time_steps = [];
    for (let h = 0; h < 24; h++) {
        time_steps.push(<option value={h} key={h}>{h}:00</option>);
    }

    // const time_zones = [];
    // for (let h = -11; h < 14; h++) {
    //     if (h < 0) {
    //         time_zones.push(<option value={h * 60} key={h}>GMT{h}</option>);
    //     }
    //     else {
    //         time_zones.push(<option value={h * 60} key={h}>GMT+{h}</option>);
    //     }
    // }

    const initDemoSchedule = {
        days: [],
        from: '',
        to: '',
        tz_offset: 0,
        tz_offset_h: 0,
        timezone: '',
        language: 'en',
        name: '',
        email: '',
        isSubmitting: false
    }

    const [demoSchedule, setDemoSchedule] = useState(initDemoSchedule);
    const [errorMsg, setErrorMsg] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [timezone, setTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    );

    const updateTz = (tz_input) => {
        setTimezone(tz_input);
        setDemoSchedule({
            ...demoSchedule,
            tz_offset: tz_input.offset * 60,
            tz_offset_h: tz_input.offset
        })
    }

    const toggleDay = (day) => {
        if (day == null) return;

        let updated_days_array = [];
        if (demoSchedule.days.includes(day)) {
            updated_days_array = demoSchedule.days.filter(current_day => current_day !== day);
        }
        else {
            updated_days_array = [...demoSchedule.days, day];
        }

        setDemoSchedule({
            ...demoSchedule,
            days: updated_days_array
        })
    }

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const bookDemo = async () => {


        setErrorMsg('');

        if (demoSchedule.days.length === 0) {
            setErrorMsg(t('select_a_day'));
            return;
        }

        if (!demoSchedule.from) {
            setErrorMsg(t('select_from_time'));
            return;
        }

        if (!demoSchedule.to) {
            setErrorMsg(t('select_to_time'));
            return;
        }

        if (!demoSchedule.name) {
            setErrorMsg(t('select_name'));
            return;
        }

        if (!demoSchedule.email || !isEmail(demoSchedule.email)) {
            setErrorMsg(t('select_email'));
            return;
        }

        setDemoSchedule({
            ...demoSchedule,
            isSubmitting: true
        })


        await axios
            .post(process.env.REACT_APP_PIKA_API_URI + "/marketing/demo_call", demoSchedule)
            .then(() => {
                setDemoSchedule(initDemoSchedule);
                setSubmitted(true);
            })
            .catch(() => {
                setDemoSchedule({
                    ...demoSchedule,
                    isSubmitting: false
                });
                setErrorMsg(t('error_server'))
            });

    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div>
            <Helmet title={t('seo_demo_title', { ns: 'seo' })}
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        property: "twitter:card",
                        content: "summary_large_image"
                    },
                    {
                        property: "twitter:site",
                        content: "@social_pika"
                    },
                    {
                        property: "twitter:title",
                        content: t('seo_demo_title', { ns: 'seo' })
                    },
                    {
                        property: "twitter:description",
                        content: t('seo_demo_description', { ns: 'seo' })
                    },
                    {
                        property: "twitter:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "og:url",
                        content: "https://www.socialpika.com/demo"
                    },
                    {
                        property: "og:type",
                        content: "website"
                    },
                    {
                        property: "og:title",
                        content: t('seo_demo_title', { ns: 'seo' })
                    },
                    {
                        property: "og:description",
                        content: t('seo_demo_description', { ns: 'seo' })
                    },
                    {
                        property: "og:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "fb:app_id",
                        content: process.env.REACT_APP_FB_APP_ID
                    }
                ]}  >
                <link rel="canonical" href="https://www.socialpika.com/demo" />
            </Helmet>
            <HeaderMDN />

            <div className='content_section'>
                <div className="col centered margin-t4">
                    <h1>{t('book_demo')}</h1>
                    <p>{t('book_sub')}</p>
                    <img className='img-fluid margin-t4' src={DemoHero} alt="SocialPika demonstration via Zoom" />
                </div>
                <div className='row margin-t4'>
                    <div className='col flex-3'>
                        <h4>{t('booking_title')}</h4>
                    </div>
                    <div className='col flex-1'></div>
                    <div className='col flex-3'>
                        <label>{t('preferred_days')}</label>
                        <div className='row margin-t1 row_nowrap'>
                            <div className={demoSchedule.days.includes(1) ? "day_button selected" : "day_button"} onClick={() => toggleDay(1)}>{t('mon')}</div>
                            <div className={demoSchedule.days.includes(2) ? "day_button selected" : "day_button"} onClick={() => toggleDay(2)}>{t('tue')}</div>
                            <div className={demoSchedule.days.includes(3) ? "day_button selected" : "day_button"} onClick={() => toggleDay(3)}>{t('wed')}</div>
                            <div className={demoSchedule.days.includes(4) ? "day_button selected" : "day_button"} onClick={() => toggleDay(4)}>{t('thu')}</div>
                            <div className={demoSchedule.days.includes(5) ? "day_button selected" : "day_button"} onClick={() => toggleDay(5)}>{t('fri')}</div>

                        </div>
                        <div className='row row-100 nowrap margin-t2'>
                            <div className='col'>
                                <label>{t('from_time')}</label>
                                <select className="time_select" value={demoSchedule.from} onChange={e => setDemoSchedule({ ...demoSchedule, from: e.target.value })}>
                                    <option value="">--:--</option>
                                    {time_steps}
                                </select>
                            </div>
                            <div className='col margin-s1'>
                                <label>{t('to_time')}</label>
                                <select className="time_select" value={demoSchedule.to} onChange={e => setDemoSchedule({ ...demoSchedule, to: e.target.value })}>
                                    <option value="">--:--</option>
                                    {time_steps}
                                </select>
                            </div>
                            <div className='col'>
                                <label>{t('time_zone')}</label>
                                {/* <select className="time_select" value={demoSchedule.tz_offset} onChange={e => setDemoSchedule({ ...demoSchedule, tz_offset: e.target.value })}>
                                    <option value="">--:--</option>
                                    {time_zones}
                                </select> */}
                                <TimezoneSelect
                                    className='tz-select'
                                    value={timezone}
                                    onChange={updateTz}
                                />
                            </div>
                        </div>
                        <div className='col margin-t2'>
                            <label>{t('pref_language')}</label>
                            <select value={demoSchedule.language} onChange={e => setDemoSchedule({ ...demoSchedule, language: e.target.value })}>
                                <option value="en">English</option>
                                <option value="fr">Français</option>
                            </select>
                        </div>
                        <div className='col margin-t2'>
                            <label>{t('name')}</label>
                            <input type="text" placeholder="John Smith" value={demoSchedule.name} onChange={e => setDemoSchedule({ ...demoSchedule, name: e.target.value })} />
                        </div>
                        <div className='col margin-t2'>
                            <label>{t('email_address')}</label>
                            <input type="email" placeholder="johnsmith@gmail.com" value={demoSchedule.email} onChange={e => setDemoSchedule({ ...demoSchedule, email: e.target.value })} required />
                        </div>
                        <div className="call-block margin-t2">
                            {submitted ?
                                <div className='submitted'>{t('request_sent')}</div>
                                : demoSchedule.isSubmitting ?
                                    <div className="lds-ring-loader-small"><div></div><div></div><div></div><div></div></div>
                                    :
                                    <div className="call" onClick={bookDemo} disabled={demoSchedule.isSubmitting}>{t('demo_button')}</div>
                            }
                            <p className='p-small text-red margin-s1'>{errorMsg}</p>
                        </div>
                    </div>
                </div>
            </div>
            <FooterMDN />
        </div>
    )
}

export default DemoMDN