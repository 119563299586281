import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet"
import axios from "axios";
import moment from 'moment'

import HeaderMDN from '../Header/HeaderMDN';
import FooterMDN from '../Header/FooterMDN';

import {
    FacebookShareButton,
    LineShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    LineIcon,
    LinkedinIcon,
    RedditIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import "./blog.css";


const BlogArticle = (props) => {
    const location = useLocation();
    const articleContentOutputRef = React.createRef();

    const initArticleData = {
        title: "",
        subtitle: "",
        main_img_url: "",
        content: ""
    }

    const [articleData, setArticleData] = useState(initArticleData);

    useEffect(() => {
        window.scrollTo(0, 0);
        const formatted_input = articleData.content;
        articleContentOutputRef.current.innerHTML = formatted_input;
    }, [articleData]);

    const fetchPostData = async () => {
        if (location.state) {
            setArticleData(location.state.post[0]);
        }
        else {
            const post_url = props.match.params.id;
            await axios
                .get(process.env.REACT_APP_PIKA_API_URI + "/blog/get_article_by_url/" + post_url)
                .then((response) => {
                    if (response.data[0]) {
                        setArticleData(response.data[0]);
                    }
                    else {
                        props.history.push('/blog');
                    }
                })
                .catch((error) => {
                    props.history.push('/blog');
                });
        }
    }

    useEffect(() => {
        fetchPostData();
    }, [location.state, props.match.params.id, props.history]);


    const [scrollButtonIsVisible, setScrollButtonIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 500) {
                setScrollButtonIsVisible(true);
            } else {
                setScrollButtonIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);
        window.scrollTo(0, 0)
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div>
            <Helmet title={articleData.title}
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        property: "twitter:card",
                        content: "summary_large_image"
                    },
                    {
                        property: "twitter:site",
                        content: "@social_pika"
                    },
                    {
                        property: "twitter:title",
                        content: articleData.title
                    },
                    {
                        property: "twitter:description",
                        content: articleData.subtitle
                    },
                    {
                        property: "twitter:image",
                        content: articleData.main_img_url
                    },
                    {
                        property: "og:url",
                        content: "https://www.socialpika.com/blog/" + articleData.post_url
                    },
                    {
                        property: "og:type",
                        content: "website"
                    },
                    {
                        property: "og:title",
                        content: articleData.title
                    },
                    {
                        property: "og:description",
                        content: articleData.subtitle
                    },
                    {
                        property: "og:image",
                        content: articleData.main_img_url
                    },
                    {
                        property: "fb:app_id",
                        content: process.env.REACT_APP_FB_APP_ID
                    }
                ]} >
                <link rel="canonical" href={"https://www.socialpika.com/blog/" + articleData.post_url} />

            </Helmet>
            <HeaderMDN />
            <div className="blog-container">
                <div className='margin-t8'>

                    <img className='blog-img' src={articleData.main_img_url} alt={articleData.subtitle} />
                    <h1>{articleData.title}</h1>
                    <h4>{articleData.subtitle}</h4>
                </div>
                <div className='blog-section-content margin-t8'>
                    <div className="sidebar">
                        <div className='sidebar-wrapper'>
                            {articleData.toc && articleData.toc.length > 0 ?
                                <>
                                    <div className="title">Table of content</div>
                                    <div className="sidebar_content">
                                        <ul className="table_of_content">
                                            {articleData.toc.map((title, t) => {
                                                return (
                                                    <li key={`toc_${t}`} dangerouslySetInnerHTML={{ __html: title }}></li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </>
                                : ''}
                            <div className="title">Share this article</div>
                            <div className="sidebar_content share">
                                <FacebookShareButton
                                    url={process.env.REACT_APP_PIKA_URI + '/blog/' + articleData.post_url}
                                    quote={articleData.subtitle}
                                    hashtag="#SocialMediaMarketing"
                                    className="share-button"
                                >
                                    <FacebookIcon size={26} round />
                                </FacebookShareButton>
                                <LinkedinShareButton
                                    url={process.env.REACT_APP_PIKA_URI + '/blog/' + articleData.post_url}
                                    title={articleData.title}
                                    summary={articleData.subtitle}
                                    source={process.env.REACT_APP_PIKA_URI + '/blog/' + articleData.post_url}
                                    className="share-button"
                                >
                                    <LinkedinIcon size={26} round />
                                </LinkedinShareButton>
                                <TwitterShareButton
                                    url={process.env.REACT_APP_PIKA_URI + '/blog/' + articleData.post_url}
                                    title={articleData.title}
                                    via="@social_pika"
                                    hashtags='["#SocialMediaMarketing"]'
                                    className="share-button"
                                >
                                    <TwitterIcon size={26} round />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={process.env.REACT_APP_PIKA_URI + '/blog/' + articleData.post_url}
                                    className="share-button"
                                >
                                    <WhatsappIcon size={26} round />
                                </WhatsappShareButton>
                                <LineShareButton
                                    url={process.env.REACT_APP_PIKA_URI + '/blog/' + articleData.post_url}
                                    title={articleData.title}
                                    className="share-button"
                                >
                                    <LineIcon size={26} round />
                                </LineShareButton>
                                <RedditShareButton
                                    url={process.env.REACT_APP_PIKA_URI + '/blog/' + articleData.post_url}
                                    title={articleData.title}
                                    className="share-button"
                                >
                                    <RedditIcon size={26} round />
                                </RedditShareButton>
                            </div>
                            <div className="title">Date</div>
                            <div className="sidebar_content">{moment(articleData.updatedAt).format('MMM Do, YYYY')}</div>
                            <div className="title">Author</div>
                            <div className="sidebar_content">P.Y.</div>
                        </div>
                    </div>
                    <div className='blog-content-block' ref={articleContentOutputRef}>
                        {articleData.content}
                    </div>
                </div>
                {scrollButtonIsVisible && (
                    <div className="scroll-to-top" onClick={scrollToTop}>
                        <svg viewBox="0 0 24 24">
                            <path fill="current" d="M13,20H11V8L5.5,13.5L4.08,12.08L12,4.16L19.92,12.08L18.5,13.5L13,8V20Z" />
                        </svg>
                    </div>
                )}
            </div>
            <FooterMDN />
        </div>
    )
}

export default BlogArticle