import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import axios from "axios";

import HeaderMDN from '../Header/HeaderMDN';
import FooterMDN from '../Header/FooterMDN';

import '../styles/mdn_faq.css';


const FAQ = () => {
    const { t, i18n } = useTranslation(['faqMDN', 'seo']);

    const faqAnswersRef = useRef();


    const [showLoader, setShowLoader] = useState(false);

    const toggleQuestion = (id, css_state) => {
        setFaqQuestions(faqQuestions.map((element, i) => i === id ? { ...element, active: !css_state } : element));
    };

    const scrollToAnswers = () => {
        faqAnswersRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }


    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [faqQuestions, setFaqQuestions] = useState([]);
    const [faqMenu, setFaqMenu] = useState([]);
    const [faqTitle, setFaqTitle] = useState('');
    const [structuredFaq, setStructuredFaq] = useState('');



    const fetchFAQMenu = async () => {
        const payload = {
            language: i18n.language
        }

        await axios
            .post(process.env.REACT_APP_PIKA_API_URI + "/faq/get_menu", payload)
            .then((response) => {
                setFaqMenu(response.data);
            })
            .catch(() => {
            });
    }

    const fetchFAQ = async () => {
        setShowLoader(true);
        const payload = {
            language: i18n.language,
            sub_cat_id: selectedSubCategory
        }

        await axios
            .post(process.env.REACT_APP_PIKA_API_URI + "/faq/get_faq", payload)
            .then((response) => {
                setFaqQuestions(response.data.faq);
                setFaqTitle(response.data.title);

                let main_entity = [];

                response.data.all_faq.map(qnr => {
                    let entity = {
                        "@type": "Question",
                        "name": qnr.question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": qnr.answer
                        }
                    }

                    main_entity.push(entity);
                })


                let structured_data = {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": main_entity
                }


                // return structured_data;
                setStructuredFaq(JSON.stringify(structured_data));

            })
            .catch(() => {
            });
        setShowLoader(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFAQ();
        fetchFAQMenu();
    }, []);

    useEffect(() => {
        fetchFAQ();

    }, [selectedSubCategory]);

    useEffect(() => {
        setSelectedSubCategory('');
        fetchFAQ();
        fetchFAQMenu();
    }, [i18n.language]);

    return (
        <div>
            <Helmet title={t('seo_faq_title', { ns: 'seo' })}
                htmlAttributes={{ lang: "en" }}
                meta={[
                    {
                        property: "twitter:card",
                        content: "summary_large_image"
                    },
                    {
                        property: "twitter:site",
                        content: "@social_pika"
                    },
                    {
                        property: "twitter:title",
                        content: t('seo_faq_title', { ns: 'seo' })
                    },
                    {
                        property: "twitter:description",
                        content: t('seo_faq_description', { ns: 'seo' })
                    },
                    {
                        property: "twitter:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "og:url",
                        content: "https://www.socialpika.com/faq"
                    },
                    {
                        property: "og:type",
                        content: "website"
                    },
                    {
                        property: "og:title",
                        content: t('seo_faq_title', { ns: 'seo' })
                    },
                    {
                        property: "og:description",
                        content: t('seo_faq_description', { ns: 'seo' })
                    },
                    {
                        property: "og:image",
                        content: "https://ik.imagekit.io/6e11zunjmq1/new_OG_optimized_JlG8cJ-Hr.png"
                    },
                    {
                        property: "fb:app_id",
                        content: process.env.REACT_APP_FB_APP_ID
                    }
                ]}
            >
                <script type="application/ld+json">{structuredFaq}</script>
                <link rel="canonical" href="https://www.socialpika.com/faq" />
            </Helmet>
            {showLoader ?
                <div className="loader">
                    <div className="loader_bg"></div>
                    <div className="lds-ring-loader-white"><div></div><div></div><div></div><div></div></div>
                </div>
                : ""}
            <HeaderMDN />
            <div className='content_section'>
                <div className="col centered margin-t4">
                    <h1>{t('faq_title')}</h1>
                    <p>{t('faq_sub_title')}</p>
                    <div className="cta-block margin-t2">
                        <div className='row vcentered'>
                            <a className="cta" href="https://app.socialpika.com/signup">{t('faq_cta')}</a>
                        </div>
                    </div>
                </div>
                <div className="row margin-t8">
                    <div className="col faq-index">
                        {faqMenu && faqMenu.length > 0 ?
                            faqMenu.map(mainCategory => {
                                return (
                                    <div key={mainCategory._id}>
                                        <h4>{mainCategory.name}</h4>
                                        <ul className="faq-list">
                                            {mainCategory.sub_categories && mainCategory.sub_categories.length > 0 ?
                                                mainCategory.sub_categories.map(subCat => {
                                                    return (
                                                        <li key={subCat._id} onClick={() => { setSelectedSubCategory(subCat._id); scrollToAnswers(); }}>{subCat.name}</li>
                                                    )
                                                })
                                                :
                                                ""
                                            }
                                        </ul>
                                    </div>
                                )
                            })
                            : ""}
                    </div>
                    <div className="flex-1"></div>
                    <div className="col faq-answers" ref={faqAnswersRef}>
                        <h2 className="margin-b1">{faqTitle}</h2>
                        {faqQuestions.map((qna, i) => {
                            return (
                                <div className={qna.active ? "faq_bloc active" : "faq_bloc"} key={i} onClick={e => toggleQuestion(i, qna.active)}>
                                    <div className="row vcentered row_spaced question row_nowrap">
                                        <h5>{qna.question}</h5>
                                        <div className="dropdown-button">
                                            <svg viewBox="0 0 24 24">
                                                <path fill="current" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <p className="answer" dangerouslySetInnerHTML={{ __html: qna.answer }}>
                                    </p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <FooterMDN />
        </div>
    )
}

export default FAQ